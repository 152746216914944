import React from "react"
import CategoryWrapper from "../../components/CategoryWrapper"

function WomenJacketsPage() {
  return (
    <CategoryWrapper
      seoTitle="Jakker"
      gender="dame"
      category="jackets"
    />
  )
}

export default WomenJacketsPage
